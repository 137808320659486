@font-face {
  font-family: "Chapeau";
  src: url("/fonts/ChapeauWEB-Regular.woff2") format("woff2"),
    url("/fonts/ChapeauWEB-Regular.woff") format("woff");
}

@font-face {
  font-family: "Chapeau-bold";
  src: url("/fonts/ChapeauWEB-Bold.woff2") format("woff2"),
    url("/fonts/ChapeauWEB-Bold.woff") format("woff");
}

@font-face {
  font-family: "Chapeau-light";
  src: url("/fonts/ChapeauWEB-Light.woff2") format("woff2"),
    url("/fonts/ChapeauWEB-Light.woff") format("woff");
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Chapeau;
  background-color: transparent;
}
input,
button {
  font-family: Chapeau;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
